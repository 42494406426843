<template>
  <div class="home">
    <!-- <div class="banner-bg">
      <img :src="bannerImg" />
      <div class="banner">
        <div class="school-box">
          <div class="welcome">新闻资讯活动</div>
          <div class="school-name">芜湖师范幼教集团</div>
        </div>
      </div>
    </div> -->
    <div class="notice" style="padding: 0px 20px">
      <div class="my-title">
        <div class="title-label" style="height: 2%">
          <span class="span">通知公告</span>
          <span class="line"></span>
        </div>
        <div class="title-go" @click="goNoticeAll()">查看全部 ></div>
      </div>
    </div>
    <div class="my-page" style="flex: 1; overflow: scroll; margin-top: 10px;margin-bottom:10px">
      <template v-if="noticeList.length > 0">
        <div class="notice">
          <div style="height: 100%; display: flex; flex-direction: column">
            <div class="notice-item" style="flex: 1; overflow: scroll">
              <div
                class="my-card"
                v-for="(index, i) in noticeList"
                :key="i"
                @click="goNoticeView(index)"
              >
                <div class="card-main">
                  <div class="card_left">
                    <p class="title">{{ index.Title }}</p>
                    <p class="desc">{{ index.Desc }}</p>
                    <div>
                      <span class="date">{{ index.CreateDate }}</span>
                    </div>
                  </div>
                  <div class="card_right">
                    <img
                      :src="index.TitleImage ? index.TitleImage : defaultImg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <van-empty description="暂无数据" v-else />
    </div>
    
    <div class="notice" style="padding: 0px 20px">
      <div class="my-title">
        <div class="title-label" style="height: 2%">
          <span class="span">新闻资讯</span>
          <span class="line"></span>
        </div>
        <div class="title-go" @click="goNewsAll()" style="z-index:100">查看全部 ></div>
      </div>
    </div>
    <div class="my-page" style="flex: 1; overflow: scroll; margin-top: 10px">
      <template v-if="dataList.length > 0">
        <div class="notice">
          <div style="height: 100%; display: flex; flex-direction: column">
            <div class="notice-item" style="flex: 1; overflow: scroll">
              <div
                class="my-card"
                v-for="(index, i) in dataList"
                :key="i"
                @click="goNoticeView(index)"
              >
                <div class="card-main">
                  <div class="card_left">
                    <p class="title">{{ index.Title }}</p>
                    <p class="desc">{{ index.Desc }}</p>
                    <div>
                      <span class="date">{{ index.CreateDate }}</span>
                    </div>
                  </div>
                  <div class="card_right">
                    <img
                      :src="index.TitleImage ? index.TitleImage : defaultImg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <van-empty description="暂无数据" v-else />
    </div>
  </div>
</template>
<script>
import bannerImg from "@/assets/home/parentSchool.png";
import bannerBgImg from "@/assets/img/green-banner.png";
import defaultImg from "@/assets/img/defaultImg.png";
import { Empty } from "vant";
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      defaultImg: defaultImg,
      bannerBgImg: bannerBgImg,
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      userType: window.localStorage.getItem("UserType"),
      bannerImg: bannerImg,
      dataList: [],
      noticeList: [],
    };
  },
  mounted() {
    // 获取通知公告
    this.getParentSchool();
    this.noticeLastList();
  },
  methods: {
    getParentSchool() {
      this.$axios
        .get("/api/NewsNotice/IndexListForApp", {
          category: 2,
          page: 1,
          limit: 6,
        })
        .then((res) => {
          this.dataList = res.data;
        });
    },
    noticeLastList() {
      this.$axios
        .get("/api/NewsNotice/IndexListForApp", {
          category: 1,
          page: 1,
          limit: 6,
        })
        .then((res) => {
          this.noticeList = res.data;
        });
    },
    goNewsAll() {
      this.$router.push({
        path: "NewsNoticeList",
		query: {
			category: 2,
        },
      });
    },
	goNoticeAll(){
		this.$router.push({
        path: "NewsNoticeList",
		query: {
			category: 1,
        },
      });
	},
    goNoticeView(item) {
      this.$router.push({
        path: "NewsNoticeView",
        query: {
          id: item.Id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");

.van-swipe-item {
  margin: 0px 10px;
}
</style>
